import React, { useEffect } from "react";
import Card from "../components/Card";
import Button from "../components/Button";
import Label from "../components/Label";
import Loader from "../components/Loader";
import Colors from "../constants/Colors";
import { useDispatch, useSelector } from "react-redux";
import VideoPlayer from "../components/VideoPlayer";
import { setLessonActive, setLessonLoading } from "../redux/videoCourses";

export default function SectionVideo() {
  const dispatch = useDispatch();
  const lesson = useSelector((state) => state.videoCourse.lesson);
  const lessonActive = useSelector(
    (state) => state.videoCourse.lessonActive.item
  );
  const lessonIndex = useSelector(
    (state) => state.videoCourse.lessonActive.index
  );
  const lessonLoading = useSelector((state) => state.videoCourse.lessonLoading);

  useEffect(() => {
    dispatch(setLessonLoading(false));
  }, [lessonActive]);

  if (lessonLoading) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: [{ translateX: -25 }, { translateY: -25 }],
        }}
      >
        <Loader loading={lessonLoading} size={50} />
      </div>
    );
  }

  const prev = () => {
    if (lessonIndex !== 0) {
      dispatch(setLessonLoading(true));
      dispatch(
        setLessonActive({
          item: lesson[lessonIndex - 1],
          index: lessonIndex - 1,
        })
      );
    }
  };

  const next = () => {
    if (lessonIndex < lesson.length - 1) {
      dispatch(setLessonLoading(true));
      dispatch(
        setLessonActive({
          item: lesson[lessonIndex + 1],
          index: lessonIndex + 1,
        })
      );
    }
  };

  const checkNext = () => lessonIndex < lesson.length - 1;
  const checkPrev = () => lessonIndex !== 0;

  return (
    <div className="sectionVideo-wrapper mt-3">
      <div className="d-flex align-items-center">
        <p className="heading me-4">{lessonActive.name}</p>
        <Label labelText={"Video Corso"} labelColor={Colors.primary} />
      </div>
      <Card style={{ padding: 0, overflow: "hidden" }}>
        <VideoPlayer link={lessonActive.link} />
      </Card>
      <Card>
        <p className="heading heading-card">Descrizione</p>
        <div dangerouslySetInnerHTML={{ __html: lessonActive.description }} />
      </Card>
      <div className="d-flex justify-content-evenly align-items-center mt-3">
        <Button
          name={"Precedente"}
          type={"outline"}
          style={{ marginRight: 10, opacity: checkPrev() ? 1 : 0.5 }}
          onClick={() => {
            if (checkPrev()) {
              prev();
            }
          }}
        />
        <Button
          name={"Prossimo Video"}
          type={"primary"}
          style={{ marginLeft: 10, opacity: checkNext() ? 1 : 0.5 }}
          onClick={() => {
            if (checkNext()) {
              next();
            }
          }}
        />
      </div>
    </div>
  );
}
