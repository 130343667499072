import React, { useState, useEffect } from "react";
import "bootstrap/dist/js/bootstrap.bundle";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Footer from "./components/Footer";
import Home from "./layout/Home";
import Login from "./layout/Login";
import Percorso from "./layout/Percorso";
import Profile from "./layout/Profile";
import Alimentazione from "./layout/Alimentazione";
import { useDispatch, useSelector } from "react-redux";
import ProtectedRoute from "./components/ProtectedRoute";
import Header from "./components/Header";
import Allenamenti from "./layout/Allenamenti";
import Parametri from "./layout/Parametri";
import Anamnesi from "./layout/Anamnesi";
import Disponibili from "./layout/Disponibili";
import Consultant from "./layout/Consultant";
import Extra from "./layout/Extra";
import Cart from "./layout/Cart";
import Notifications from "./layout/Notifications";
import Faq from "./layout/Faq";
import Startup from "./layout/Startup";
import { getToken, login, logout, setTypeAuth } from "./redux/auth";
import { api } from "./api/api";
import SelectPlate from "./layout/SelectPlate";
import CourseDetails from "./layout/CourseDetails";
import BookDetails from "./layout/BookDetails";
import MyCourseDetails from "./layout/MyCourseDetails";
import MyBookDetails from "./layout/MyBookDetails";
import CourseSection from "./layout/CourseSection";
import SectionVideo from "./layout/SectionVideo";
import ActivationClient from "./layout/ActivationClient";
import RegisterClient from "./layout/RegisterClient";
import ForgotPassword from "./layout/ForgotPassword";
import ResetPassword from "./layout/ResetPassword";
import AppOnly from "./components/AppOnly";
import CookiePopup from "./components/CookiePopup";
import LegalModal from "./components/LegalModal";

function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.auth.typeAuth);
  const [cookiePopupVisible, setCookiePopupVisible] = useState(false);
  const [legalModalVisible, setLegalModalVisible] = useState(false);

  useEffect(() => {
    setCookiePopupVisible(!isAuth)
  }, [isAuth])

  useEffect(() => {
    getToken().then((storeToken) => {
      if (!storeToken) {
        console.log("No Token", storeToken);
        return dispatch(setTypeAuth({ typeAuth: false }));
      }

      console.log("Refresh Token");
      api
        .post("/login/refresh")
        .then((res) => {
          if (res.data.token) {
            console.log("Set Token");
            dispatch(login({ token: res.data.token, autologin: true }));
          } else {
            dispatch(logout());
          }
        })
        .catch((err) => {
          dispatch(logout());
        });
    });
  }, []);

  const onAcceptCookies = () => {
    localStorage.setItem(
      "CookieOptions",
      JSON.stringify({ tecnici: true, profiliazione: true, statistica: true })
    );
  };

  return (
    <React.Fragment>
      <div className="d-none d-md-block">
        {legalModalVisible && (
          <LegalModal
            onAcceptSelected={() => {
              setLegalModalVisible(false);
              onAcceptCookies();
            }}
          />
        )}
        {cookiePopupVisible && (
          <CookiePopup
            onManage={() => {
              setLegalModalVisible(true);
              setCookiePopupVisible(false);
            }}
            onAccept={() => {
              setCookiePopupVisible(false);
              onAcceptCookies();
            }}
          />
        )}
        {isAuth && <Header />}
        <Routes>
          {isAuth === null && <Route path="/" element={<Startup />} />}
          {isAuth === false && <Route path="/" element={<Login />} />}
          {isAuth && <Route path="/" element={<Home />} />}
          <Route path="/activation" element={<ActivationClient />} />
          <Route path="/register" element={<RegisterClient />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
          <Route
            path="/percorso/*"
            element={
              <ProtectedRoute isAuth={isAuth}>
                <Percorso />
              </ProtectedRoute>
            }
          >
            <Route path="alimentazione/*" element={<Alimentazione />}>
              <Route path="select-plate" element={<SelectPlate />} />
            </Route>
            <Route path="allenamenti" element={<Allenamenti />} />
            <Route path="parametri" element={<Parametri />} />
            <Route path="anamnesi" element={<Anamnesi />} />
            <Route path="disponibili/*" element={<Disponibili />}>
              <Route path="myCourseDetails/*" element={<MyCourseDetails />}>
                <Route path="courseSection/*" element={<CourseSection />}>
                  <Route path="sectionVideo" element={<SectionVideo />} />
                </Route>
              </Route>
              <Route path="myBookDetails/*" element={<MyBookDetails />} />
            </Route>
            <Route path="consultant" element={<Consultant />} />
          </Route>
          <Route
            path="/extra"
            element={
              <ProtectedRoute isAuth={isAuth}>
                <Extra />
              </ProtectedRoute>
            }
          />
          <Route
            path="/extra/courseDetails"
            element={
              <ProtectedRoute isAuth={isAuth}>
                <CourseDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/extra/bookDetails"
            element={
              <ProtectedRoute isAuth={isAuth}>
                <BookDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/cart"
            element={
              <ProtectedRoute isAuth={isAuth}>
                <Cart />
              </ProtectedRoute>
            }
          />
          <Route
            path="/notifications"
            element={
              <ProtectedRoute isAuth={isAuth}>
                <Notifications />
              </ProtectedRoute>
            }
          />
          <Route
            path="/faq"
            element={
              <ProtectedRoute isAuth={isAuth}>
                <Faq />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute isAuth={isAuth}>
                <Profile />
              </ProtectedRoute>
            }
          />
        </Routes>
        {(location.pathname === "/" ||
          location.pathname === "/activation" ||
          location.pathname === "/register" ||
          location.pathname === "/forgotPassword" ||
          location.pathname === "/resetPassword") && <Footer />}
      </div>
      <div className="d-md-none d-sm-block">
        <AppOnly />
      </div>
    </React.Fragment>
  );
}

export default App;
