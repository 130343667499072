import React, { useState } from "react";
import { HiX } from "react-icons/hi";
import Card from "./Card";
import Button from "./Button";
import Colors from "../constants/Colors";
import { InputCheck } from "./Input";
import { api } from "../api/api";
import { useSelector, useDispatch } from "react-redux";
import { fetchParams, setParamsLoading } from "../redux/profile";
import ImagePicker from "./ImagePicker";
import Popup from "../components/Popup";

export default function CheckModal(props) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const [collo, setCollo] = useState("");
  const [braccio, setBraccio] = useState("");
  const [vita, setVita] = useState("");
  const [fianchi, setFianchi] = useState("");
  const [coscia, setCoscia] = useState("");
  const [kg, setKg] = useState("");
  const [altezza, setAltezza] = useState("");
  const [frontImg, setFrontImg] = useState();
  const [sideImg, setSideImg] = useState();
  const [backImg, setBackImg] = useState();
  const [imageLoading, setImageLoading] = useState(false);
  const [showPopup, setShowPopup] = useState({
    visible: false,
    heading: "",
    message: "",
    button: "",
    onClose: null,
    onClick: null,
  });

  const massaGrassa =
    495 /
      (1.29579 -
        0.35004 *
          Math.log10(parseInt(vita) + parseInt(fianchi) - parseInt(collo)) +
        0.221 * Math.log10(parseInt(altezza))) -
    450;
  const mg = Math.round(massaGrassa);

  const updateCheck = () => {
    api
      .post("/profile/add_check", {
        collo: collo,
        braccio: braccio,
        vita: vita,
        fianchi: fianchi,
        coscia: coscia,
        kg: kg,
        massa_grassa: mg,
        front_img: frontImg,
        side_img: sideImg,
        back_img: backImg,
      })
      .then((response) => {
        console.log("check updated");
        setShowPopup({
          visible: true,
          heading: "SAVED",
          message: "Your check was saved successfully!",
          button: "OK",
          onClose: () => setShowPopup({ visible: false }),
          onClick: () => {
            dispatch(setParamsLoading(true));
            dispatch(fetchParams());
            setShowPopup({ visible: false });
          },
        });
      })
      .catch((err) => {
        setShowPopup({
          visible: true,
          heading: "ERROR",
          message: "Please try again later!",
          button: "OK",
          onClose: () => setShowPopup({ visible: false }),
          onClick: () => setShowPopup({ visible: false }),
        });
        console.log("err", err.message);
      });
  };

  const uploadImage = async (checkImg, id) => {
    setImageLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append("img[]", checkImg, checkImg.name);
    formdata.append("type", "checkParam");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch("https://api3.metodofespa-app.com/api/img/upload", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (id === 1) {
          setFrontImg(data.id.response[0]);
        } else if (id === 2) {
          setSideImg(data.id.response[0]);
        } else if (id === 3) {
          setBackImg(data.id.response[0]);
        }
        setImageLoading(false);
      })
      .catch((error) => {
        console.log("error uploading image", error);
        setImageLoading(false);
      });
  };

  const checkValidity = () => {
    if (
      !!collo &&
      !!braccio &&
      !!vita &&
      !!fianchi &&
      !!coscia &&
      !!kg &&
      !!frontImg &&
      !!sideImg &&
      !!backImg &&
      !!altezza
    ) {
      updateCheck();
    } else {
      setShowPopup({
        visible: true,
        heading: "ATTENTION",
        message: "All fields are required!",
        button: "Try Again",
        onClose: () => setShowPopup({ visible: false }),
        onClick: () => setShowPopup({ visible: false }),
      });
    }
  };

  return (
    <div className="check">
      {showPopup.visible && (
        <Popup
          visible={showPopup.visible}
          heading={showPopup.heading}
          message={showPopup.message}
          onClose={showPopup.onClose}
          onClick={showPopup.onClick}
          button={showPopup.button}
        />
      )}
      <a type="button" data-bs-toggle="modal" data-bs-target="#checkModal">
        {props.openModal}
      </a>

      <div
        className={`modal fade overlay-dark`}
        id="checkModal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable check-modal-dialog">
          <div className="modal-content">
            <div className="modal-header check-modal-header justify-content-center">
              <button
                type="button"
                className="check-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <HiX size={30} />
              </button>
              <p className="check-title">Aggiungi Check</p>
            </div>
            <div className="modal-body">
              <div className="check-body-wrapper">
                <div className="row justify-content-md-center">
                  <div className="col-8 col-md-12">
                    <Card>
                      <p className="heading heading-card">
                        Carica la tua foto Frontale
                      </p>
                      <div className="check-image-wrapper">
                        <ImagePicker
                          onImageTaken={(imagePath) =>
                            imagePath && uploadImage(imagePath, 1)
                          }
                        />
                      </div>
                    </Card>
                    <Card>
                      <p className="heading heading-card">
                        Carica la tua foto Laterale
                      </p>
                      <div className="check-image-wrapper">
                        <ImagePicker
                          onImageTaken={(imagePath) =>
                            imagePath && uploadImage(imagePath, 2)
                          }
                        />
                      </div>
                    </Card>
                    <Card>
                      <p className="heading heading-card">
                        Carica la tua foto Spalle
                      </p>
                      <div className="check-image-wrapper">
                        <ImagePicker
                          onImageTaken={(imagePath) =>
                            imagePath && uploadImage(imagePath, 3)
                          }
                        />
                      </div>
                    </Card>
                  </div>
                  <div className="col-4 col-lg-8 col-md-12">
                    <Card>
                      <p className="heading heading-card">
                        Measurement parameters
                      </p>
                      <InputCheck
                        number
                        placeholder="e.g. 70 kg"
                        description="peso corpo (kg)*"
                        onChange={(e) => setKg(e.target.value)}
                        maxLength={3}
                      />
                      <InputCheck
                        number
                        placeholder="e.g. 70 cm"
                        description="collo (cm)*"
                        onChange={(e) => setCollo(e.target.value)}
                        maxLength={2}
                      />
                      <InputCheck
                        number
                        placeholder="e.g. 70 cm"
                        description="braccio (cm)*"
                        onChange={(e) => setBraccio(e.target.value)}
                        maxLength={2}
                      />
                      <InputCheck
                        number
                        placeholder="e.g. 70 cm"
                        description="vita (cm)*"
                        onChange={(e) => setVita(e.target.value)}
                        maxLength={3}
                      />
                      <InputCheck
                        number
                        placeholder="e.g. 70 cm"
                        description="fianchi (cm)*"
                        onChange={(e) => setFianchi(e.target.value)}
                        maxLength={2}
                      />
                      <InputCheck
                        number
                        placeholder="e.g. 70 cm"
                        description="coscia (cm)*"
                        onChange={(e) => setCoscia(e.target.value)}
                        maxLength={2}
                      />
                      <InputCheck
                        number
                        placeholder="e.g. 170 cm"
                        description="altezza (cm)*"
                        onChange={(e) => setAltezza(e.target.value)}
                        maxLength={3}
                      />
                    </Card>
                    <Button
                      type={"primary"}
                      name={imageLoading ? "Loading Images" : "Fatto"}
                      style={{
                        marginTop: 20,
                        backgroundColor: imageLoading
                          ? Colors.primaryLightShadow
                          : Colors.primary,
                      }}
                      onClick={() => !imageLoading && checkValidity()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
