import axios from "axios";
//import AsyncStorage from "@react-native-async-storage/async-storage";
import { logout } from "../redux/auth";

let store;

export const injectStore = (_store) => {
  store = _store;
};

export const apiUrl = "https://api3.metodofespa-app.com/api";

export const api = axios.create();
api.defaults.baseURL = apiUrl;

api.interceptors.request.use(
  async (config) => {
    let data = localStorage.getItem("token");

    if (data) {
      config.headers.Authorization = "Bearer " + data;
    } else {
      // token is empty
      store.dispatch(logout());
      console.log("token is empty");
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (res) {
    if (
      res.data?.message === "Token is Expired" ||
      res.data?.message === "bad_token"
    ) {
      console.log("bad token api-inter-res ", res.data.message);
      store.dispatch(logout());
    } else if (res.data?.message === "Token is Invalid") {
      console.log("bad token api-inter-res ", res.data.message);
    }
    return res;
  },
  function (error) {
    return Promise.reject(error);
  }
);
