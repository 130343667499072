import React, { useEffect } from "react";
import Card from "../components/Card";
import ImageRender from "../components/ImageRender";
import InformationCard from "../components/InformationCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchConsultantData, setConsultantLoading } from "../redux/profile";
import Loader from "../components/Loader";

export default function Consultant() {
  const dispatch = useDispatch();
  const consultant = useSelector((state) => state.profile.consultant);
  const consultantLoading = useSelector(
    (state) => state.profile.consultantLoading
  );

  useEffect(() => {
    dispatch(setConsultantLoading(true));
    dispatch(fetchConsultantData());
  }, []);

  if (consultantLoading) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: [{ translateX: -25 }, { translateY: -25 }],
        }}
      >
        <Loader loading={consultantLoading} size={50} />
      </div>
    );
  }

  return (
    <div className="container consultant pb-3">
      <Card>
        <div className="consultant-wrapper">
          <p className="heading mb-4">Consulente Personale</p>
          <div className="row">
            <div className="col-6">
              <div className="consultant-image-wrapper">
                <ImageRender
                  idImg={consultant?.images}
                  initialImg={
                    "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                  }
                />
              </div>
            </div>
            <div className="col-6">
              <Card style={{ marginTop: 0, height: "100%" }}>
                <p className="heading heading-card">
                  Il Tuo Consulente Personale
                </p>
                <InformationCard
                  placeholder="Nome e Cognome"
                  info={consultant?.contact_name || ""}
                />
                <InformationCard
                  placeholder="Email"
                  info={consultant?.email || ""}
                />
                <InformationCard
                  placeholder="WhatsApp"
                  info={consultant?.whatsapp || ""}
                />
              </Card>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
