import React, { useState, useEffect } from "react";
import Card from "../components/Card";
import DishCard from "../components/DishCard";
import Filter from "../components/Filter";
import Colors from "../constants/Colors";
import Loader from "../components/Loader";
import Button from "../components/Button";
import IngredientCard from "../components/IngredientCard";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDish,
} from "../redux/dish";
import { api } from "../api/api";
import { fetchFood, fetchFoodCategory, setFoodCategory } from "../redux/food";
import {
  setItemModalDish,
  setItemModalFood,
} from "../redux/menu";
import { alimentiCategory } from "../helper/categoryName";
import DishDetails from "../components/DishDetails";
import { useNavigate } from "react-router-dom";
import {
  HiArrowLeft,
  HiArrowDown,
  HiArrowUp,
  HiOutlineArrowCircleRight,
  HiOutlineArrowCircleLeft,
  HiSearch,
} from "react-icons/hi";
import ClipLoader from "react-spinners/ClipLoader";
import AlimentiDetails from "../components/AlimentiDetails";
import { setAllergyTrigger } from "../redux/filter";

export default function SelectPlate(props) {
  const navigate = useNavigate();
  const [ricetteActive, setRicetteActive] = useState(true);
  const [alimentiActive, setAlimentiActive] = useState(false);
  const [preferitiActive, setPreferitiActive] = useState(false);

  return (
    <div className="selectPlate">
      <Card>
        <div className="selectPlate-nav d-flex">
          <div onClick={() => navigate(-1)}>
            <HiArrowLeft
              size={25}
              style={{ color: Colors.primary, marginRight: 20 }}
            />
          </div>
          <div
            className={
              ricetteActive
                ? "selectPlate-nav-items selectPlate-nav-itemsActive"
                : "selectPlate-nav-items"
            }
            onClick={() => {
              setRicetteActive(true);
              setAlimentiActive(false);
              setPreferitiActive(false);
            }}
          >
            <p>Ricette</p>
          </div>
          <div
            className={
              alimentiActive
                ? "selectPlate-nav-items selectPlate-nav-itemsActive"
                : "selectPlate-nav-items"
            }
            onClick={() => {
              setRicetteActive(false);
              setAlimentiActive(true);
              setPreferitiActive(false);
            }}
          >
            <p>Alimenti</p>
          </div>
          <div
            className={
              preferitiActive
                ? "selectPlate-nav-items selectPlate-nav-itemsActive"
                : "selectPlate-nav-items"
            }
            onClick={() => {
              setRicetteActive(false);
              setAlimentiActive(false);
              setPreferitiActive(true);
            }}
          >
            <p>Preferiti</p>
          </div>
        </div>
      </Card>
      {ricetteActive && <Ricette />}
      {alimentiActive && <Alimenti />}
      {preferitiActive && <Preferiti />}
    </div>
  );
}

const DishRicetteCard = ({ category, name }) => {
  const dispatch = useDispatch();
  const [initial, setInitial] = useState(0);
  const [slice, setSlice] = useState(6);
  const [showPag, setShowPag] = useState(false);

  return (
    <Card>
      <div className="d-flex justify-content-between">
        <p className="heading">{name}</p>
        <div
          onClick={() => {
            if (slice >= 12) {
              setInitial(0);
              setSlice(6);
              setShowPag(!showPag);
            } else {
              setSlice(12);
              setShowPag(!showPag);
            }
          }}
        >
          {slice < 12 ? (
            <div className="d-flex align-items-center">
              <p
                style={{
                  marginBottom: 0,
                  fontSize: 12,
                  color: Colors.primary,
                }}
              >
                DI PIU
              </p>
              <HiArrowDown
                size={18}
                style={{ color: Colors.primary, marginLeft: 10 }}
              />
            </div>
          ) : (
            <div className="d-flex align-items-center">
              <p
                style={{
                  marginBottom: 0,
                  fontSize: 12,
                  color: Colors.primary,
                }}
              >
                NASCONDI
              </p>
              <HiArrowUp
                size={18}
                style={{ color: Colors.primary, marginLeft: 10 }}
              />
            </div>
          )}
        </div>
      </div>
      <div className="d-flex ricette-container">
        {category.length > 0 &&
          category.slice(initial, slice).map((item, index) => (
            <div
              key={item.id}
              onClick={() => {
                dispatch(setItemModalDish(item));
              }}
            >
              <DishDetails
                id={`${item.id}-${index}`}
                img={item.image}
                openModal={
                  <DishCard
                    key={index}
                    img={item.image}
                    name={item.name}
                    carb={item.T_carboidrati}
                    gras={item.T_grassi}
                    prot={item.T_proteine}
                    labelText={`${item.T_energia} kcal`}
                    labelColor={Colors.green}
                  />
                }
              />
            </div>
          ))}
      </div>
      {showPag && (
        <div className="d-flex align-items-center align-self-center mt-2">
          <div
            onClick={() => {
              if (initial > 0) {
                setInitial(slice - 24);
                setSlice(slice - 12);
              }
            }}
            className="me-2"
          >
            <HiOutlineArrowCircleLeft
              size={30}
              color={initial > 0 ? Colors.primary : Colors.primaryLightShadow}
            />
          </div>
          <div
            onClick={() => {
              if (slice <= category.length) {
                setInitial(slice);
                setSlice(slice + 12);
              }
            }}
            className="ms-2"
          >
            <HiOutlineArrowCircleRight
              size={30}
              color={
                slice <= category.length
                  ? Colors.primary
                  : Colors.primaryLightShadow
              }
            />
          </div>
        </div>
      )}
    </Card>
  );
};

const Ricette = (props) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const dish = useSelector((state) => state.dish.list);
  const dishLoading = useSelector((state) => state.dish.loading);
  const activeAllergy = useSelector((state) => state.filter.activeAllergy);
  const listOrEmpty = Object.keys(activeAllergy).length === 0 ? "off" : "list";
  const allergyTrigger = useSelector((state) => state.filter.allergyTrigger);
  const [filteredList, setFilteredList] = useState([]);
  const [filteredListLoading, setFilteredListLoading] = useState(true);
  const dishList = filteredList || dish;
  const [iconShow, setIconShow] = useState(false);

  useEffect(() => {
    dispatch(fetchDish());
  }, []);

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append("category", "off");
    formdata.append("category_content", "{}");
    formdata.append("priorita", "off");
    formdata.append("priorita_content", "{}");
    formdata.append("periodTime", "off");
    formdata.append("periodTimeContent", "{}");
    formdata.append("allergen", listOrEmpty);
    formdata.append("allergenContent", JSON.stringify({ data: activeAllergy }));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://api3.metodofespa-app.com/api/dish/smartFilter33",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setFilteredListLoading(true);
        setFilteredList(result.dish);
        setFilteredListLoading(false);
      })
      .catch((error) => console.log("error", error));
  }, [allergyTrigger]);

  const iconTimeout = () => {
    setTimeout(() => {
      setIconShow(false);
    }, 3000);
  };

  const dishCategories = dishList.map((el) => el.category);

  const antipasti = [];
  const primiPiatti = [];
  const secondiPiatti = [];
  const contorni = [];
  const frutta = [];
  const condimenti = [];
  const dessert = [];

  dishList.map((el) => {
    const cat = el.category;
    if (cat === "Antipasti") {
      antipasti.push(el);
    } else if (cat === "Primi Piatti") {
      primiPiatti.push(el);
    } else if (cat === "Secondi Piatti") {
      secondiPiatti.push(el);
    } else if (cat === "Contorni") {
      contorni.push(el);
    } else if (cat === "Frutta") {
      frutta.push(el);
    } else if (cat === "Condimenti") {
      condimenti.push(el);
    } else if (cat === "Dessert") {
      dessert.push(el);
    }
  });

  if (dishLoading) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: [{ translateX: -25 }, { translateY: -25 }],
        }}
      >
        <ClipLoader color={Colors.primary} loading={dishLoading} size={50} />
      </div>
    );
  }

  return (
    <div>
      <Card>
        <div className="d-flex justify-content-between align-items-center mb-md-3">
          <p className="heading heading-card mb-2">Escludi Alergeni</p>
          <div
            onClick={() => dispatch(setAllergyTrigger())}
            className="filterRefresh"
          >
            <p className="filterRefresh-text">Refresh Filter</p>
          </div>
        </div>
        <div className="d-flex">
          <Filter />
        </div>
      </Card>
      <DishRicetteCard category={antipasti} name={"Antipasti"} />
      <DishRicetteCard category={primiPiatti} name={"Primi Piatti"} />
      <DishRicetteCard category={secondiPiatti} name={"Secondi Piatti"} />
      <DishRicetteCard category={contorni} name={"Contorni"} />
      <DishRicetteCard category={frutta} name={"Frutta"} />
      <DishRicetteCard category={condimenti} name={"Condimenti"} />
      <DishRicetteCard category={dessert} name={"Dessert"} />
    </div>
  );
};

const FoodTypeAlimenti = ({ item, name }) => {
  const dispatch = useDispatch();
  const activeCategory = useSelector((state) => state.food.activeCategoryFood);

  const select = () => {
    dispatch(setFoodCategory(item));
  };

  return (
    <div onClick={() => select()}>
      <div
        className={
          activeCategory === item ? "filter-itemActive " : "filter-item"
        }
      >
        <p
          className={
            activeCategory === item
              ? "filter-itemTextActive "
              : "filter-itemText"
          }
        >
          {name}
        </p>
      </div>
    </div>
  );
};

const AlimentiCard = ({ search }) => {
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [slice, setSlice] = useState(15);
  const activeCategory = useSelector((state) => state.food.activeCategoryFood);
  const [loading, setLoading] = useState(false);
  const quantity = useSelector((state) => state.menu.quantity);
  const [iconShow, setIconShow] = useState(false);

  useEffect(() => {
    let unmounted = false;
    setLoading(true);

    api
      .post("/food/ByCategory", { category: activeCategory })
      .then((res) => {
        if (!unmounted) {
          setList(res.data.foods);
          //setSlice(slice + 15);
        }
      })
      .finally(() => {
        if (!unmounted) setLoading(false);
      });

    return () => (unmounted = true);
  }, [activeCategory]);

  const getList = () => {
    if (search.length > 2) {
      return list.filter((item) => {
        return item.name.toLowerCase().includes(search.toLowerCase());
      });
    } else {
      return list;
    }
  };

  if (loading) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: [{ translateX: -25 }, { translateY: -25 }],
        }}
      >
        <Loader loading={loading} size={50} />
      </div>
    );
  }

  if (search.length > 2 && getList().length === 0) {
    return null;
  }

  const iconTimeout = () => {
    setTimeout(() => {
      setIconShow(false);
    }, 3000);
  };

  return (
    <Card>
      <div
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p className="heading heading-card">
          {alimentiCategory(activeCategory)} ({getList()?.length || 0})
        </p>
      </div>
      <div className="d=flex justify-content-center">
        {getList()?.length > 0 &&
          getList()
            .slice(0, slice)
            .map((item, index) => (
              <div
                key={item.id}
                onClick={() => {
                  dispatch(setItemModalFood(item));
                }}
              >
                <AlimentiDetails
                  id={`${item.id}-${index}`}
                  img={item.image}
                  openModal={
                    <IngredientCard
                      key={item.id}
                      name={item.name || ""}
                      weight={`${item.weight}g`}
                      kcal={item.energia || 0}
                      carb={item.carboidrati || 0}
                      gras={item.grassi || 0}
                      prot={item.proteine || 0}
                      style={{ width: 500 }}
                    />
                  }
                />
              </div>
            ))}
      </div>
      {slice > 0 && getList()?.length > slice ? (
        <div
          onClick={() => {
            setSlice(slice + 15);
          }}
          className="mt-3"
        >
          <Button name="More" type="outline" />
        </div>
      ) : null}
    </Card>
  );
};

const Alimenti = () => {
  const dispatch = useDispatch();
  const category = useSelector((state) => state.food.category);
  const foodLoading = useSelector((state) => state.food.loading);
  const [search, setSearch] = useState("");
  const shownCategory = category.slice(0, -1);

  useEffect(() => {
    dispatch(fetchFoodCategory());
    dispatch(fetchFood());
  }, []);

  return (
    <div>
      <div className="d-flex flex-wrap mt-4">
        {shownCategory?.length > 0 &&
          shownCategory.map((el) => (
            <FoodTypeAlimenti item={el} key={el} name={alimentiCategory(el)} />
          ))}
      </div>
      <div className="alimenti_search-container mt-3 d-flex align-items-center">
        <HiSearch
          size={20}
          color={Colors.primaryLight}
          style={{ marginRight: 10 }}
        />
        <input
          className="alimenti_input"
          placeholder="Cerca"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <AlimentiCard search={search} />
    </div>
  );
};

const DishAlimentiCard = ({ category, name }) => {
  const dispatch = useDispatch();
  const [initial, setInitial] = useState(0);
  const [slice, setSlice] = useState(6);
  const [showPag, setShowPag] = useState(false);

  return (
    <Card>
      <div className="d-flex justify-content-between">
        <p className="heading">{name}</p>
        <div
          onClick={() => {
            if (slice >= 12) {
              setInitial(0);
              setSlice(6);
              setShowPag(!showPag);
            } else {
              setSlice(12);
              setShowPag(!showPag);
            }
          }}
        >
          {slice < 12 ? (
            <div className="d-flex align-items-center">
              <p
                style={{
                  marginBottom: 0,
                  fontSize: 12,
                  color: Colors.primary,
                }}
              >
                DI PIU
              </p>
              <HiArrowDown
                size={18}
                style={{ color: Colors.primary, marginLeft: 10 }}
              />
            </div>
          ) : (
            <div className="d-flex align-items-center">
              <p
                style={{
                  marginBottom: 0,
                  fontSize: 12,
                  color: Colors.primary,
                }}
              >
                NASCONDI
              </p>
              <HiArrowUp
                size={18}
                style={{ color: Colors.primary, marginLeft: 10 }}
              />
            </div>
          )}
        </div>
      </div>
      <div className="d-flex ricette-container">
        {category.length > 0 &&
          category.slice(initial, slice).map((item, index) => (
            <div
              key={item.id}
              onClick={() => {
                dispatch(setItemModalFood(item));
              }}
            >
              <AlimentiDetails
                id={`${item.id}-${index}`}
                img={item.image}
                openModal={
                  <DishCard
                    key={index}
                    img={item.image}
                    name={item.name}
                    carb={item.carboidrati}
                    gras={item.grassi}
                    prot={item.proteine}
                    labelText={`${item.energia} kcal`}
                    labelColor={Colors.green}
                  />
                }
              />
            </div>
          ))}
      </div>
      {showPag && (
        <div className="d-flex align-items-center align-self-center mt-2">
          <div
            onClick={() => {
              if (initial > 0) {
                setInitial(slice - 24);
                setSlice(slice - 12);
              }
            }}
            className="me-2"
          >
            <HiOutlineArrowCircleLeft
              size={30}
              color={initial > 0 ? Colors.primary : Colors.primaryLightShadow}
            />
          </div>
          <div
            onClick={() => {
              if (slice <= category.length) {
                setInitial(slice);
                setSlice(slice + 12);
              }
            }}
            className="ms-2"
          >
            <HiOutlineArrowCircleRight
              size={30}
              color={
                slice <= category.length
                  ? Colors.primary
                  : Colors.primaryLightShadow
              }
            />
          </div>
        </div>
      )}
    </Card>
  );
};

const Preferiti = () => {
  const favoriteDish = useSelector(
    (state) => state.favorites.favorites.favoriteDish
  );
  const favoriteFood = useSelector(
    (state) => state.favorites.favorites.favoriteFood
  );

  const favoritesCategories = favoriteDish.map((el) => el.category);

  const antipasti = [];
  const primiPiatti = [];
  const secondiPiatti = [];
  const contorni = [];
  const frutta = [];
  const condimenti = [];
  const dessert = [];

  favoriteDish.map((el) => {
    const cat = el.category;
    if (cat === "Antipasti") {
      antipasti.push(el);
    } else if (cat === "Primi Piatti") {
      primiPiatti.push(el);
    } else if (cat === "Secondi Piatti") {
      secondiPiatti.push(el);
    } else if (cat === "Contorni") {
      contorni.push(el);
    } else if (cat === "Frutta") {
      frutta.push(el);
    } else if (cat === "Condimenti") {
      condimenti.push(el);
    } else if (cat === "Dessert") {
      dessert.push(el);
    }
  });

  if (favoriteDish.length === 0 && favoriteFood.length === 0) {
    return (
      <div className="d-flex justify-content-center align-items-center mt-5">
        <p className="heading heading-card">No favorites yet, just add some!</p>
      </div>
    );
  }

  return (
    <div>
      {favoritesCategories.includes("Antipasti") && (
        <DishRicetteCard category={antipasti} name={"Antipasti"} />
      )}
      {favoritesCategories.includes("Primi Piatti") && (
        <DishRicetteCard category={primiPiatti} name={"Primi Piatti"} />
      )}
      {favoritesCategories.includes("Secondi") && (
        <DishRicetteCard category={secondiPiatti} name={"Secondi"} />
      )}
      {favoritesCategories.includes("Contorni") && (
        <DishRicetteCard category={contorni} name={"Contorni"} />
      )}
      {favoritesCategories.includes("Frutta") && (
        <DishRicetteCard category={frutta} name={"Frutta"} />
      )}
      {favoritesCategories.includes("Condimenti") && (
        <DishRicetteCard category={condimenti} name={"Condimenti"} />
      )}
      {favoritesCategories.includes("Dessert") && (
        <DishRicetteCard category={dessert} name={"Dessert"} />
      )}
      {favoriteFood.length > 0 && (
        <DishAlimentiCard category={favoriteFood} name={"Alimenti"} />
      )}
    </div>
  );
};
