import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../api/api";

export const fetchVideoCourseAll = createAsyncThunk(
  "video_course/all",
  async function () {
    const { data } = await api.get("/video_course/all2");
    return data.list;
  }
);
export const fetchVideoCourseById = createAsyncThunk(
  "video_course/ById",
  async function (id) {
    const { data } = await api.post("/video_course/ById", { id });
    return data.list[0];
  }
);


const initialState = {
  list: [],
  loading: false,
  courseType: "shopItem",
  item: {},
  itemLoading: false,
  sessione: [],
  sessioneLoading: false,
  sessioneActive: {
    item: null,
    index: null
  },
  lesson: [],
  lessonLoading: false,
  lessonActive: {
    item: null,
    index: null
  }
};

export const videoCourse = createSlice({
  name: "videoCourse",
  initialState,
  reducers: {
    setVideoCourseItem: (state, action) => {
      state.item = action.payload;
    },
    setCourseType: (state, action) => {
      state.courseType = action.payload;
    },
    setVideoCourseLoading: (state, action) => {
      state.loading = action.payload;
    },
    setSessione: (state, action) => {
      state.sessione = action.payload
      state.sessioneLoading = false
    },
    setSessioneActive: (state, action) => {
      state.sessioneActive.item = action.payload.item;
      state.sessioneActive.index = action.payload.index;
    },
    setSessioneLoading: (state, action) => {
      state.sessioneLoading = action.payload
    },
    setLesson: (state, action) => {
      state.lesson = action.payload
      state.lessonLoading = false
    },
    setLessonActive: (state, action) => {
      state.lessonActive.item = action.payload.item;
      state.lessonActive.index = action.payload.index;
    },
    setLessonLoading: (state, action) => {
      state.lessonLoading = action.payload
    },
    clearVideoCourseList: (state, action) => {
      state.list = [];
    },
  },
  extraReducers: {
    [fetchVideoCourseAll.fulfilled]: (state, action) => {
      state.list = action.payload.map((item) => {
        return { ...item, type: "videoCourse" };
      });
      state.loading = false;
    },
    [fetchVideoCourseById.fulfilled]: (state, action) => {
      state.item = action.payload;
      state.loading = false;
    },
  },
});

export const {
  setVideoCourseLoading,
  setCourseType,
  clearVideoCourseList,
  setVideoCourseItem,
  setSessione,
  setSessioneActive,
  setSessioneLoading,
  setLesson,
  setLessonActive,
  setLessonLoading,
} = videoCourse.actions;
export default videoCourse.reducer;
