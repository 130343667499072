import React from "react";
import IgnoreHeader from "../components/IgnoreHeader";
import { useSelector } from "react-redux";
import Label from "../components/Label";
import Colors from "../constants/Colors";
import { useNavigate } from "react-router-dom";
import { HiChevronLeft } from "react-icons/hi";
import Card from "../components/Card";
import ImageRender from "../components/ImageRender";
import Button from "../components/Button";

export default function BookDetails() {
  const navigate = useNavigate();
  const book = useSelector((state) => state.books.item);

  return (
    <IgnoreHeader>
      <div className="container bookDetails pb-3">
        <Card>
          <div className="d-flex align-items-center">
            <HiChevronLeft color={Colors.primary} size={20} />
            <p className="courseDetails-nav" onClick={() => navigate(-1)}>
              Prodotti Extra
            </p>
          </div>
          <div className="bookDetails-wrapper mt-2">
            <div className="d-flex align-items-center mb-3">
              <p className="heading me-3">{book.name}</p>
              <Label labelText={"Video Corso"} labelColor={Colors.primary} />
            </div>
            <div className="bookDetails-image">
              <Card style={{ padding: 0, overflow: "hidden", height: "100%" }}>
                <ImageRender idImg={book.image} />
              </Card>
            </div>
            {book.start_price > 0 && (
              <div className="d-flex justify-content-end align-items-center mt-3">
                {book.discount > 0 && (
                  <div className="courseDetails-items">
                    <p className="courseDetails-items-text">sconto</p>
                    <p className="courseDetails-items-content">
                      {`${book.discount}%`}
                    </p>
                  </div>
                )}
                <div>
                  <p className="courseDetails-info-price">{`€${book.final_price}`}</p>
                  {book.start_price > book.final_price && (
                    <p className="productVideo-info-firstPrice">{`al posto di €${book.start_price}`}</p>
                  )}
                </div>
              </div>
            )}

            <Card>
              <p className="heading heading-card">Descrizione</p>
              <div dangerouslySetInnerHTML={{ __html: book.description }} />
            </Card>
            <div className="mt-3">
              {book.type === "amazon" ? (
                <a href={book.link} target="blank">
                  <Button type={"primary"} name={"Acquista su Amazon"} />
                </a>
              ) : (
                <Button
                  type={"primary"}
                  name={"Richiedi Informazioni"}
                  onClick={() => navigate("../../percorso/consultant")}
                />
              )}
            </div>
          </div>
        </Card>
      </div>
    </IgnoreHeader>
  );
}
