import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {api} from "../api/api";

export const fetchFood = createAsyncThunk(
  'food/all',
  async function () {
    const response = await api.get('/food/all')
    return response.data.foods
  }
)

export const fetchFoodByCategory = createAsyncThunk(
  'food/ByCategory',
  async function (category) {
    console.log('get')
    const response = await api.post('/food/ByCategory', {category})
    return {
      list: response.data.foods,
      category
    }
  }
)

export const fetchFoodCategory = createAsyncThunk(
  'food/category',
  async function () {
    const response = await api.get('/food/category')
    return response.data.category
  }
)

const initialState = {
  list: [],
  category: [],
  loading: [],
  item: {},
  activeCategoryFood: ''
};

export const food = createSlice({
  name: "food",
  initialState,
  reducers: {
    setDishItem: (state, action) => {
      state.item = action.payload
    },
    setDishLoading: (state, action) => {
      state.loading = action.payload
    },
    setFoodCategory: (state, action) => {
      state.activeCategoryFood = action.payload
    },
  },
  extraReducers: {
    [fetchFood.fulfilled]: (state, action) => {
      state.list = action.payload
      state.loading = false
    },
    [fetchFoodByCategory.fulfilled]: (state, action) => {
      state.list.push({
        type: action.payload.category,
        list: action.payload.list
      })
      state.loading = false
    },
    [fetchFoodCategory.fulfilled]: (state, action) => {
      state.category = action.payload
      state.activeCategoryFood = state.activeCategoryFood || action.payload[0]
      state.loading = false
    },
  },
});


export const {setFoodLoading, setFoodCategory} = food.actions;
export default food.reducer;
