import React from "react";

export default function Input(props) {
  return (
    <div className="input-wrapper">
      <input
        className="input-component"
        placeholder={props.placeholder}
        onChange={props.onChange}
      />
      {props.hint && <p className="input-hint">{props.hint}</p>}
    </div>
  );
}

export function InputCheck(props) {
  return (
    <div className="inputCheck-wrapper" style={props.style}>
      <p className="inputCheck-description">{props.description}</p>
      <input
        className="inputCheck-component"
        type={props.number ? "number" : "text"}
        placeholder={props.placeholder}
        onChange={props.onChange}
        maxLength={props.maxLength}
      />
    </div>
  );
}
