import React, { useEffect, useState } from "react";
import Card from "../components/Card";
import Colors from "../constants/Colors";
import Label from "../components/Label";
import Button from "../components/Button";
import VideoPlayer from "../components/VideoPlayer";
import { renderImg } from "../api/requests";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchSessione,
  sessioneActive,
  setSessioneLoading,
  setSessioneCategory,
  setSessioneCategoryLoading,
} from "../redux/profile";
import { fetchExerciseById, setExerciseLoading } from "../redux/exercise";
import { checkCategory } from "../helper/categoryName";
import Loader from "../components/Loader";
import ClosedContentCard from "../components/ClosedContentCard";
import { HiPlay } from "react-icons/hi";
import IntroAllenamentiModal from "../components/IntroAllenamentiModal";

export default function Allenamenti(props) {
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.profile.userType);
  const userStatus = useSelector((state) => state.profile.userStatus);
  const sessione = useSelector((state) => state.profile.sessione);
  const sessioneLoading = useSelector((state) => state.profile.sessioneLoading);
  const sessioneCategory = useSelector(
    (state) => state.profile.sessioneCategory
  );
  const sessioneCategoryLoading = useSelector(
    (state) => state.profile.sessioneCategoryLoading
  );
  const exerciseItem = useSelector((state) => state.exercise.item);
  const sessioneActiveItem = useSelector(
    (state) => state.profile.sessioneActive
  );
  const exerciseLoading = useSelector((state) => state.exercise.loading);
  const [coverImage, setCoverImage] = useState();
  const [exIndex, setExIndex] = useState(0);

  useEffect(() => {
    if (!exerciseItem) return;
    getImage(exerciseItem.image);
  }, [exerciseItem]);

  const getImage = (imageId) => {
    if (!imageId) return;
    renderImg(imageId).then((res) => {
      setCoverImage(res);
    });
  };

  useEffect(() => {
    dispatch(setSessioneLoading(true));
    dispatch(fetchSessione());
  }, []);

  useEffect(() => {
    if (!sessioneLoading) {
      dispatch(setSessioneCategoryLoading(true));
      dispatch(setSessioneCategory(sessione?.session_json[0]));
    }
  }, [sessione]);

  useEffect(() => {
    if (!sessioneCategoryLoading) {
      dispatch(
        sessioneActive({
          exerciziItem: sessioneCategory?.eserciziInfo[0],
          index: 0,
          sessioneIndex: 0,
        })
      );
      dispatch(setExerciseLoading(true));
      dispatch(fetchExerciseById(sessioneCategory?.eserciziInfo[0].id));
    }
  }, [sessioneCategory]);

  const goTo = (exerciziItem, index, sessioneIndex) => {
    dispatch(sessioneActive({ exerciziItem, index, sessioneIndex }));
    dispatch(setExerciseLoading(true));
    dispatch(fetchExerciseById(exerciziItem.id));
  };

  if (userType !== "Vincente" || userStatus !== "Attivo") {
    return (
      <ClosedContentCard
        heading="Programma Allenamenti"
        content="Richiedi maggiori informazioni sui nostri percorsi di allenamento personalizzati"
        buttonText="Watch Video"
      />
    );
  }

  if (sessioneLoading || sessioneCategoryLoading || exerciseLoading) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: [{ translateX: -25 }, { translateY: -25 }],
        }}
      >
        <Loader
          loading={
            sessioneLoading || sessioneCategoryLoading || exerciseLoading
          }
          size={50}
        />
      </div>
    );
  }

  const sessioneList = sessione?.session_json[sessioneActiveItem.sessioneIndex];

  const prev = () => {
    if (
      sessioneList?.eserciziInfo.length > sessioneActiveItem?.index &&
      sessioneActiveItem?.index > 0
    ) {
      const index = +sessioneActiveItem?.index - 1;
      changeSession(index);
    }
  };

  const next = () => {
    if (
      sessioneList?.eserciziInfo.length - 1 > sessioneActiveItem?.index &&
      sessioneActiveItem?.index >= 0
    ) {
      const index = +sessioneActiveItem?.index + 1;
      changeSession(index);
    }
  };

  const checkNext = () => {
    return sessioneList?.eserciziInfo.length - 1 <= sessioneActiveItem?.index;
  };
  const checkPrev = () => {
    return sessioneActiveItem?.index <= 0;
  };

  const changeSession = (index) => {
    const exerciziItem = sessioneList?.eserciziInfo[index];

    dispatch(
      sessioneActive({
        exerciziItem,
        index,
        sessioneIndex: sessioneActiveItem?.sessioneIndex,
      })
    );
    dispatch(setExerciseLoading(true));
    dispatch(fetchExerciseById(exerciziItem?.id));
  };

  return (
    <div className="container allenamenti pb-3">
      <div className="row">
        <div className="col-xl-2 col-lg-2 col-md-12 pb-3">
          <Card style={{ height: "100%" }}>
            {sessione?.session_json.map((item, index) => (
              <div
                key={index}
                className={
                  index === exIndex
                    ? "allenamenti-sessione-container_active mb-3"
                    : "allenamenti-sessione-container mb-3"
                }
                onClick={() => {
                  dispatch(setSessioneCategory(item));
                  setExIndex(index);
                }}
              >
                <p
                  className={
                    index === exIndex
                      ? "allenamenti-sessione-text_active"
                      : "allenamenti-sessione-text"
                  }
                >{`Sessione ${index + 1}`}</p>
              </div>
            ))}
          </Card>
        </div>
        <div className="col-xl-10 col-lg-10 col-md-12">
          <Card>
            <div className="allenamenti-content-wrapper">
              <div className="d-flex justify-content-evenly mb-3">
                {sessioneCategory?.eserciziInfo.map((item, index) => (
                  <div
                    key={index}
                    onClick={() =>
                      index !== sessioneActiveItem.index
                        ? changeSession(index)
                        : null
                    }
                    className="d-flex align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    <div
                      className={
                        index === sessioneActiveItem.index
                          ? "allenamenti-pag allenamenti-pag_active"
                          : "allenamenti-pag"
                      }
                    >
                      <p
                        className={
                          index === sessioneActiveItem.index
                            ? "allenamenti-pag-text allenamenti-pag-text_active"
                            : "allenamenti-pag-text"
                        }
                      >
                        {index + 1}
                      </p>
                    </div>
                    <p
                      className={
                        index === sessioneActiveItem.index
                          ? "allenamenti-pag-ex allenamenti-pag-ex_active"
                          : "allenamenti-pag-ex"
                      }
                    >
                      Esercizio
                    </p>
                  </div>
                ))}
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <p className="heading mb-0 me-3">{exerciseItem?.name}</p>
                  <Label
                    labelText={checkCategory(sessioneCategory?.category)}
                    labelColor={Colors.green}
                  />
                </div>
                <IntroAllenamentiModal
                  openModal={
                    <div className="d-flex align-items-center allenamenti-video-intro">
                      <HiPlay size={25} color={Colors.primary} />
                      <p className="allenamenti-video-text">
                        Video Introduzione
                      </p>
                    </div>
                  }
                />
              </div>
              <Card
                style={{
                  padding: 0,
                  overflow: "hidden",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                <VideoPlayer link={exerciseItem?.link} />
              </Card>
              <div className="row">
                <div className="col-6">
                  <div className="d-flex">
                    <div className="allenamenti-items">
                      <p className="allenamenti-items-text">Serie</p>
                      <p className="allenamenti-items-content">
                        {sessioneActiveItem?.exerciziItem?.serie || ""}
                      </p>
                    </div>
                    <div className="allenamenti-items">
                      <p className="allenamenti-items-text">ripetizioni</p>
                      <p className="allenamenti-items-content">
                        {sessioneActiveItem?.exerciziItem?.ripetizioni || ""}
                      </p>
                    </div>
                    <div className="allenamenti-items">
                      <p className="allenamenti-items-text">recupero</p>
                      <p className="allenamenti-items-content">
                        {sessioneActiveItem?.exerciziItem?.recupero || ""}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6 ">
                  <div className="d-flex justify-content-xl-end justify-content-lg-end">
                    <div className="me-2">
                      <Button
                        name={"Precedente"}
                        type={"outline"}
                        onClick={() => prev()}
                        style={{ opacity: checkPrev() ? 0.5 : 1 }}
                      />
                    </div>
                    <div>
                      <Button
                        name={"Prossimo Video"}
                        type={"primary"}
                        onClick={() => next()}
                        style={{ opacity: checkNext() ? 0.5 : 1 }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Card>
                <p className="heading heading-card mb-3">Note</p>
                <p className="contentText">
                  {sessioneActiveItem?.exerciziItem?.note || ""}
                </p>
              </Card>
              <Card>
                <p className="heading heading-card mb-3">Descrizione</p>
                <p className="contentText">{exerciseItem?.notes || ""}</p>
              </Card>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}
