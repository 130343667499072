import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../api/api";

export const fetchMenu = createAsyncThunk("profile/my_menu", async function () {
  const response = await api.get("/profile/my_menu");
  if (
    !response.data.data ||
    !Object.keys(response.data.data).length === 0 ||
    response.data.data.length === 0
  ) {
    console.log("starting new menu");
    return initialState.menu;
  }
  return response.data.data;
});

export const updateQuantity = createAsyncThunk(
  "menu/quantity",
  async function (qt) {
    return qt;
  }
);

const checkItem = (arr, itemId) => {
  const ids = [];
  arr.map((el) => {
    ids.push(el.id);
  });

  const index = ids.indexOf(itemId);

  arr.splice(index, 1);
};

const initialState = {
  menu: {
    menuDish: {
      collazione: [],
      pranzo: [],
      cena: [],
      snack: [],
    },
    menuFood: {
      collazione: [],
      pranzo: [],
      cena: [],
      snack: [],
    },
  },
  menuLoading: true,
  categoryMenu: null,
  quantity: 0.1,
  itemModalDish: {},
  itemModalFood: {}
};

export const menu = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setCategoryMenu: (state, action) => {
      state.categoryMenu = action.payload;
    },
    setGeneratedMenu: (state, action) => {
      state.menu.menuDish.collazione = action.payload.collazione;
      state.menu.menuDish.pranzo = action.payload.pranzo;
      state.menu.menuDish.cena = action.payload.cena;
      state.menu.menuDish.snack = action.payload.snack;
    },
    setMenuDish: (state, action) => {
      if (state.categoryMenu === "collazione") {
        state.menu.menuDish.collazione.push(action.payload);
      } else if (state.categoryMenu === "pranzo") {
        state.menu.menuDish.pranzo.push(action.payload);
      } else if (state.categoryMenu === "cena") {
        state.menu.menuDish.cena.push(action.payload);
      } else if (state.categoryMenu === "snack") {
        state.menu.menuDish.snack.push(action.payload);
      } else {
        console.log("something wrong in setMenuDish");
      }
    },
    setMenuFood: (state, action) => {
      if (state.categoryMenu === "collazione") {
        state.menu.menuFood.collazione.push(action.payload);
      } else if (state.categoryMenu === "pranzo") {
        state.menu.menuFood.pranzo.push(action.payload);
      } else if (state.categoryMenu === "cena") {
        state.menu.menuFood.cena.push(action.payload);
      } else if (state.categoryMenu === "snack") {
        state.menu.menuFood.snack.push(action.payload);
      } else {
        console.log("something wrong in setMenuFood");
      }
    },
    removeDish: (state, action) => {
      if (action.payload.category === "collazione") {
        checkItem(state.menu.menuDish.collazione, action.payload.id);
      } else if (action.payload.category === "pranzo") {
        checkItem(state.menu.menuDish.pranzo, action.payload.id);
      } else if (action.payload.category === "cena") {
        checkItem(state.menu.menuDish.cena, action.payload.id);
      } else if (action.payload.category === "snack") {
        checkItem(state.menu.menuDish.snack, action.payload.id);
      } else {
        console.log("something went wrong in removeDish");
      }
    },
    removeFood: (state, action) => {
      if (action.payload.category === "collazione") {
        checkItem(state.menu.menuFood.collazione, action.payload.id);
      } else if (action.payload.category === "pranzo") {
        checkItem(state.menu.menuFood.pranzo, action.payload.id);
      } else if (action.payload.category === "cena") {
        checkItem(state.menu.menuFood.cena, action.payload.id);
      } else if (action.payload.category === "snack") {
        checkItem(state.menu.menuFood.snack, action.payload.id);
      } else {
        console.log("something went wrong in removeFood");
      }
    },
    setMenuLoading: (state, action) => {
      state.menuLoading = action.payload;
    },
    setQuantityFood: (state, action) => {
      state.menu.quantity = action.payload;
    },
    setMenuEmpty: (state, action) => {
      state.menu = {
        menuDish: {
          collazione: [],
          pranzo: [],
          cena: [],
          snack: [],
        },
        menuFood: {
          collazione: [],
          pranzo: [],
          cena: [],
          snack: [],
        },
      };
    },
    setItemModalDish: (state, action) => {
      state.itemModalDish = action.payload
    },
    setItemModalFood: (state, action) => {
      state.itemModalFood = action.payload
    }
  },
  extraReducers: {
    [fetchMenu.fulfilled]: (state, action) => {
      state.menu = action.payload;
      state.menuLoading = false;
    },
    [updateQuantity.fulfilled]: (state, action) => {
      state.quantity = action.payload;
    },
  },
});

export const {
  setGeneratedMenu,
  setCategoryMenu,
  setMenuDish,
  setMenuFood,
  setMenuLoading,
  setMenuEmpty,
  removeDish,
  removeFood,
  setItemModalDish,
  setItemModalFood
} = menu.actions;
export default menu.reducer;
