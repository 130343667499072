import React from "react";
import { Outlet, NavLink, useLocation } from "react-router-dom";
import IgnoreHeader from "../components/IgnoreHeader";
import Card from "../components/Card";

export default function Percorso() {
  const location = useLocation();

  return (
    <IgnoreHeader>
      {location.pathname !== "/percorso/alimentazione/select-plate" && (
        <div className="container">
          <Card>
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <NavLink
                  to="alimentazione"
                  className={({ isActive }) =>
                    isActive ? "link-nav activeLink" : "link-nav notActiveLink"
                  }
                >
                  <p>Alimentazione</p>
                </NavLink>
                <NavLink
                  to="allenamenti"
                  className={({ isActive }) =>
                    isActive ? "link-nav activeLink" : "link-nav notActiveLink"
                  }
                >
                  <p>Allenamenti</p>
                </NavLink>
                <NavLink
                  to="parametri"
                  className={({ isActive }) =>
                    isActive ? "link-nav activeLink" : "link-nav notActiveLink"
                  }
                >
                  <p>Check Parametri</p>
                </NavLink>
                <NavLink
                  to="anamnesi"
                  className={({ isActive }) =>
                    isActive ? "link-nav activeLink" : "link-nav notActiveLink"
                  }
                >
                  <p>Anamnesi</p>
                </NavLink>

                <NavLink
                  to="disponibili"
                  className={({ isActive }) =>
                    isActive ? "link-nav activeLink" : "link-nav notActiveLink"
                  }
                >
                  <p>Disponibile Per Me</p>
                </NavLink>
              </div>
              <NavLink
                to="consultant"
                className={({ isActive }) =>
                  isActive ? "link-nav activeLink" : "link-nav notActiveLink"
                }
              >
                <p>Consulente</p>
              </NavLink>
            </div>
          </Card>
        </div>
      )}

      <Outlet />
    </IgnoreHeader>
  );
}
