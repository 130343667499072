import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setAllergyFilter } from "../redux/filter";

export default function Filter() {
  const allergyFilter = useSelector((state) => state.filter.allergyFilter);
  const allergyList = Object.entries(allergyFilter)
    .slice(0, 13)
    .map((entry) => entry[0]);

  return (
    <div className="d-flex flex-wrap">
      {allergyList.map((el) => (
        <Ingredients item={el} key={el} />
      ))}
    </div>
  );
}

export const Ingredients = (props) => {
  const dispatch = useDispatch();
  const allergyFilter = useSelector((state) => state.filter.allergyFilter);
  const isActive = allergyFilter[props.item];

  return (
    <div
      onClick={() => {
        dispatch(
          setAllergyFilter({ item: props.item, value: isActive ? 0 : 1 })
        );
      }}
    >
      <div
        className={isActive ? "filter-itemActive " : "filter-item"}
      >
        <p className={isActive ? "filter-itemTextActive " : "filter-itemText"}>
          {props.item}
        </p>
      </div>
    </div>
  );
};
