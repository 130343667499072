import React, { useEffect, useState, useRef } from "react";
import {
  setLesson,
  setLessonActive,
  setLessonLoading,
  setSessioneActive,
  setSessioneLoading,
} from "../redux/videoCourses";
import Card from "../components/Card";
import ImageRender from "../components/ImageRender";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import Label from "../components/Label";
import Colors from "../constants/Colors";
import ProductVideoCard from "../components/ProductVideoCard";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import {
  HiOutlineArrowCircleLeft,
  HiOutlineArrowCircleRight,
} from "react-icons/hi";
import Button from "../components/Button";

export default function CourseSection() {
  const scrollRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sessioneActive = useSelector(
    (state) => state.videoCourse.sessioneActive.item
  );
  const sessioneIndex = useSelector(
    (state) => state.videoCourse.sessioneActive.index
  );
  const sessione = useSelector((state) => state.videoCourse.sessione);
  const sessioneLoading = useSelector(
    (state) => state.videoCourse.sessioneLoading
  );
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    const divElement = scrollRef.current;
    if (divElement) {
      divElement.scroll({ left: scroll, behavior: "smooth" });
    }
  }, [scroll]);

  useEffect(() => {
    dispatch(setSessioneLoading(false));
  }, [sessioneActive]);

  if (sessioneLoading) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: [{ translateX: -25 }, { translateY: -25 }],
        }}
      >
        <Loader loading={sessioneLoading} size={50} />
      </div>
    );
  }

  const lessons = sessioneActive.lessons;

  const prev = () => {
    if (sessioneIndex !== 0) {
      dispatch(setSessioneLoading(true));
      dispatch(
        setSessioneActive({
          item: sessione[sessioneIndex - 1],
          index: sessioneIndex - 1,
        })
      );
    }
  };

  const next = () => {
    if (sessioneIndex < sessione.length - 1) {
      dispatch(setSessioneLoading(true));
      dispatch(
        setSessioneActive({
          item: sessione[sessioneIndex + 1],
          index: sessioneIndex + 1,
        })
      );
    }
  };

  const checkNext = () => sessioneIndex < sessione.length - 1;
  const checkPrev = () => sessioneIndex !== 0;

  return (
    <React.Fragment>
      {location.pathname ===
        "/percorso/disponibili/myCourseDetails/courseSection" && (
        <div className="courseDetails-wrapper mt-2">
          <div className="d-flex align-items-center mb-3">
            <p className="heading me-3">{sessioneActive.name}</p>
            <Label labelText={"Video Corso"} labelColor={Colors.primary} />
          </div>
          <Card style={{ padding: 0, overflow: "hidden", height: 430 }}>
            <ImageRender idImg={sessioneActive.image} />
          </Card>
          <Card style={{ padding: 0 }}>
            <HiOutlineArrowCircleLeft
              size={30}
              color={Colors.primary}
              className="courseDetails-arrow courseDetails-arrow-left"
              onClick={() => setScroll(scroll - 250)}
            />
            <div
              className="d-flex p-3 pt-1"
              style={{ overflow: "scroll" }}
              ref={scrollRef}
            >
              {lessons.length > 0 &&
                lessons.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      dispatch(setLessonLoading(true));
                      dispatch(setLesson(lessons));
                      dispatch(
                        setLessonActive({
                          item: item,
                          index: index,
                        })
                      );
                      navigate("sectionVideo");
                    }}
                  >
                    <ProductVideoCard
                      idImg={item.image}
                      label={"contenuti"}
                      name={item.name || ""}
                      video={`video ${index + 1}`}
                    />
                  </div>
                ))}
            </div>
            <HiOutlineArrowCircleRight
              size={30}
              color={Colors.primary}
              className="courseDetails-arrow courseDetails-arrow-right"
              onClick={() => setScroll(scroll + 250)}
            />
          </Card>
          <Card>
            <p className="heading heading-card">Descrizione</p>
            <div
              dangerouslySetInnerHTML={{ __html: sessioneActive.description }}
            />
          </Card>
          <div className="d-flex justify-content-evenly align-items-center mt-3">
            <Button
              name={"Precedente"}
              type={"outline"}
              style={{ marginRight: 10, opacity: checkPrev() ? 1 : 0.5 }}
              onClick={() => {
                if (checkPrev()) {
                  prev();
                }
              }}
            />
            <Button
              name={"Prossima Sezione"}
              type={"primary"}
              style={{ marginLeft: 10, opacity: checkNext() ? 1 : 0.5 }}
              onClick={() => {
                if (checkNext()) {
                  next();
                }
              }}
            />
          </div>
        </div>
      )}

      <Outlet />
    </React.Fragment>
  );
}
