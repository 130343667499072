import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { FaWindowClose } from "react-icons/fa";
import Card from "../components/Card";
import ProgressCircle from "../components/ProgressCircle";
import Colors from "../constants/Colors";
import Button from "../components/Button";
import Filter from "../components/Filter";
import { api } from "../api/api";
import { fetchAllergyFilter } from "../redux/filter";
import { fetchPersonalDish, setPersonalDishLoading } from "../redux/profile";
import { fetchFavorites } from "../redux/favorites";
import {
  setCategoryMenu,
  setGeneratedMenu,
  setMenuEmpty,
  removeDish,
  setItemModalDish,
  setItemModalFood,
} from "../redux/menu";
import {
  sumCarbs,
  sumEnergy,
  sumGras,
  sumProte,
} from "../helper/calc/macrosSum";
import AddDish from "../components/AddDish";
import SelectPlate from "./SelectPlate";
import DishCard from "../components/DishCard";
import { useLocation, useNavigate } from "react-router-dom";
import DishDetails from "../components/DishDetails";
import AlimentiDetails from "../components/AlimentiDetails";
import Loader from "../components/Loader";
import Popup from "../components/Popup";
import ClosedContentCard from "../components/ClosedContentCard";

export default function Alimentazione() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const token = useSelector((state) => state.auth.token);
  const userType = useSelector((state) => state.profile.userType);
  const userStatus = useSelector((state) => state.profile.userStatus);
  const personalDish = useSelector((state) => state.profile.personalDish);
  const personalDishLoading = useSelector(
    (state) => state.profile.personalDishLoading
  );
  const menu = useSelector((state) => state.menu.menu);
  const menuDish = useSelector((state) => state.menu.menu.menuDish);
  const menuFood = useSelector((state) => state.menu.menu.menuFood);
  const favorites = useSelector((state) => state.favorites.favorites);
  const favoriteDish = useSelector(
    (state) => state.favorites.favorites.favoriteDish
  );
  const favoriteFood = useSelector(
    (state) => state.favorites.favorites.favoriteFood
  );
  const activeAllergy = useSelector((state) => state.filter.activeAllergy);
  const listOrEmpty = Object.keys(activeAllergy).length === 0 ? "off" : "list";
  const [generatedLoading, setGeneratedLoading] = useState(false);
  const [showPopup, setShowPopup] = useState({
    visible: false,
    heading: "",
    message: "",
    button: "",
    onClose: null,
    onClick: null,
  });

  useEffect(() => {
    dispatch(setPersonalDishLoading(true));
    dispatch(fetchPersonalDish());
    dispatch(fetchAllergyFilter());
    dispatch(fetchFavorites());
  }, []);

  useEffect(() => {
    api
      .post("/profile/change_my_menu", { json: menu })
      .then(() => console.log("Menu list uploaded"))
      .catch((err) => console.log("error uploading", err));

    //dispatch(setPersonalDishLoading(true));
    dispatch(fetchPersonalDish());
  }, [menuDish, menuFood]);

  useEffect(() => {
    api
      .post("/profile/change_preferated_dish", {
        preferated_dish: favorites,
      })
      .then((response) => console.log("Favorites list uploaded"))
      .catch((err) => console.log("error favorites"));
  }, [favoriteDish, favoriteFood]);

  if (personalDishLoading) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: [{ translateX: -25 }, { translateY: -25 }],
        }}
      >
        <Loader loading={personalDishLoading} size={50} />
      </div>
    );
  }

  if (userType !== "Vincente" || userStatus !== "Attivo") {
    return (
      <ClosedContentCard
        heading="Strategia Alimentare"
        content="Scopri il nostro percorso e raggiungi la tua liberta alimentare."
        buttonText="Watch Video"
      />
    );
  }

  const generateMenu = () => {
    api
      .get(
        `/login/generator_new_web_get?T_carboidrati=${
          personalDish.macro_carbo
        }&T_grassi=${personalDish.macro_grasi}&T_proteine=${
          personalDish.macro_prote
        }&allergen=${listOrEmpty}&allergenContent=${JSON.stringify({
          data: activeAllergy,
        })}`
      )
      .then((result) => {
        const res = result.data.json;
        setGeneratedLoading(false);
        const collazione = [];
        const pranzo = [];
        const cena = [];
        const snack = [];

        res && res.collazione.map((el) => collazione.push(el));
        res && res.pranzo.map((el) => pranzo.push(el));
        res && res.cena.map((el) => cena.push(el));
        res && res.spuntini.map((el) => snack.push(el));

        !res &&
          setShowPopup({
            visible: true,
            heading: "ATTENTION",
            message: "Stiamo cercando dei piatti migliori per te!",
            button: "Riprova",
            onClose: () => setShowPopup({ visible: false }),
            onClick: () => {
              generateMenu();
              setGeneratedLoading(true);
              setShowPopup({ visible: false });
            },
          });

        dispatch(setMenuEmpty());
        dispatch(setGeneratedMenu({ collazione, pranzo, cena, snack }));
      })
      .catch((error) => {
        setGeneratedLoading(false);
        console.log("error", error);
        setShowPopup({
          visible: true,
          heading: "ATTENTION",
          message: "Stiamo cercando dei piatti migliori per te!",
          button: "Riprova",
          onClose: () => setShowPopup({ visible: false }),
          onClick: () => {
            generateMenu();
            setGeneratedLoading(true);
            setShowPopup({ visible: false });
          },
        });
      });
  };

  const colDish = menuDish?.collazione;
  const colFood = menuFood?.collazione;
  const prDish = menuDish?.pranzo;
  const prFood = menuFood?.pranzo;
  const ceDish = menuDish?.cena;
  const ceFood = menuFood?.cena;
  const snDish = menuDish?.snack;
  const snFood = menuFood?.snack;

  const sumCollazioneEnergy = sumEnergy(colDish, colFood);
  const sumPranzoEnergy = sumEnergy(prDish, prFood);
  const sumCenaEnergy = sumEnergy(ceDish, ceFood);
  const sumSnackEnergy = sumEnergy(snDish, snFood);
  const sumCollazioneCarbs = sumCarbs(colDish, colFood);
  const sumPranzoCarbs = sumCarbs(prDish, prFood);
  const sumCenaCarbs = sumCarbs(ceDish, ceFood);
  const sumSnackCarbs = sumCarbs(snDish, snFood);
  const sumCollazioneProte = sumProte(colDish, colFood);
  const sumPranzoProte = sumProte(prDish, prFood);
  const sumCenaProte = sumProte(ceDish, ceFood);
  const sumSnackProte = sumProte(snDish, snFood);
  const sumCollazioneGras = sumGras(colDish, colFood);
  const sumPranzoGras = sumGras(prDish, prFood);
  const sumCenaGras = sumGras(ceDish, ceFood);
  const sumSnackGras = sumGras(snDish, snFood);

  const totalEnergy =
    sumCollazioneEnergy + sumPranzoEnergy + sumCenaEnergy + sumSnackEnergy;
  const totalCarbs =
    sumCollazioneCarbs + sumPranzoCarbs + sumCenaCarbs + sumSnackCarbs;
  const totalProte =
    sumCollazioneProte + sumPranzoProte + sumCenaProte + sumSnackProte;
  const totalGras =
    sumCollazioneGras + sumPranzoGras + sumCenaGras + sumSnackGras;

  const fix = (nr) => {
    const num = Number(nr);
    if (num % 1 === 0) {
      return num;
    } else {
      return num.toFixed(1);
    }
  };
  const rnd = (nr) => Math.round(nr);
  const plusTenPercent = (nr) => {
    const num = parseInt(nr);
    return num * 0.1 + num;
  };

  const calcPercentage = (pr, nr) => {
    return (nr * 100) / pr;
  };

  return (
    <div className="container pb-4">
      {showPopup.visible && (
        <Popup
          visible={showPopup.visible}
          heading={showPopup.heading}
          message={showPopup.message}
          onClose={showPopup.onClose}
          onClick={showPopup.onClick}
          button={showPopup.button}
        />
      )}
      {location.pathname === "/percorso/alimentazione" && (
        <div>
          {" "}
          <Card>
            <p className="heading">Strategia Alimentare</p>
            <p className="sub-heading">{personalDish?.strategies}</p>
            <div className="row">
              <div className="col-xl-9 col-lg-12">
                <div className="d-flex">
                  <div className="progress-wrapper">
                    <ProgressCircle
                      value={calcPercentage(
                        personalDish?.macro_carbo,
                        rnd(totalCarbs)
                      )}
                      text={`${rnd(totalCarbs)}`}
                      color={
                        rnd(totalCarbs) >
                        plusTenPercent(personalDish?.macro_carbo)
                          ? Colors.red
                          : Colors.greenLight
                      }
                      shadowColor={
                        rnd(totalCarbs) >
                        plusTenPercent(personalDish?.macro_carbo)
                          ? Colors.greenLightShadow
                          : Colors.greenLightShadow
                      }
                      textColor={
                        rnd(totalCarbs) >
                        plusTenPercent(personalDish?.macro_carbo)
                          ? Colors.red
                          : Colors.green
                      }
                    />
                    <div className="progress-details text-center">
                      <p className="macro-name">carboidrati</p>
                      <p className="consigliati">
                        consigliati {personalDish?.macro_carbo}
                      </p>
                    </div>
                  </div>
                  <div className="progress-wrapper">
                    <ProgressCircle
                      value={calcPercentage(
                        personalDish?.macro_grasi,
                        rnd(totalGras)
                      )}
                      text={`${rnd(totalGras)}`}
                      color={
                        rnd(totalGras) >
                        plusTenPercent(personalDish?.macro_grasi)
                          ? Colors.red
                          : Colors.greenLight
                      }
                      shadowColor={
                        rnd(totalGras) >
                        plusTenPercent(personalDish?.macro_grasi)
                          ? Colors.redLightShadow
                          : Colors.greenLightShadow
                      }
                      textColor={
                        rnd(totalGras) >
                        plusTenPercent(personalDish?.macro_grasi)
                          ? Colors.red
                          : Colors.green
                      }
                    />
                    <div className="progress-details text-center">
                      <p className="macro-name">grassi</p>
                      <p className="consigliati">
                        consigliati {personalDish?.macro_grasi}
                      </p>
                    </div>
                  </div>
                  <div className="progress-wrapper">
                    <ProgressCircle
                      value={calcPercentage(
                        personalDish?.macro_prote,
                        rnd(totalProte)
                      )}
                      text={`${rnd(totalProte)}`}
                      color={
                        rnd(totalProte) >
                        plusTenPercent(personalDish?.macro_prote)
                          ? Colors.red
                          : Colors.greenLight
                      }
                      shadowColor={
                        rnd(totalProte) >
                        plusTenPercent(personalDish?.macro_prote)
                          ? Colors.redLightShadow
                          : Colors.greenLightShadow
                      }
                      textColor={
                        rnd(totalProte) >
                        plusTenPercent(personalDish?.macro_prote)
                          ? Colors.red
                          : Colors.green
                      }
                    />
                    <div className="progress-details text-center">
                      <p className="macro-name">proteine</p>
                      <p className="consigliati">
                        consigliati {personalDish?.macro_prote}
                      </p>
                    </div>
                  </div>
                  <div className="progress-wrapper">
                    <ProgressCircle
                      value={100}
                      text={`${rnd(totalEnergy)}`}
                      color={Colors.primary}
                      textColor={Colors.primary}
                    />
                    <div className="progress-details text-center">
                      <p className="macro-name">KCAL</p>
                      <p className="consigliati">
                        consigliati {personalDish?.macro_kcal}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-12 ">
                <div className="generate-wrapper d-flex justify-content-xl-end">
                  <Button
                    style={{ width: 200 }}
                    name={
                      generatedLoading ? (
                        <ClipLoader
                          color={Colors.white}
                          loading={generatedLoading}
                          size={20}
                        />
                      ) : (
                        "Genera Menu"
                      )
                    }
                    type={"primary"}
                    onClick={() => {
                      generateMenu();
                      setGeneratedLoading(true);
                    }}
                  />
                </div>
              </div>
            </div>
            <div>
              <p className="heading heading-card mb-2 mt-xl-1 mt-lg-3 mt-md-3">
                Escludi Alergeni
              </p>
              <div className="d-flex">
                <Filter />
              </div>
            </div>
          </Card>
          <div className="row">
            <div className="col-xl-6 col-lg-12">
              <Card>
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <p className="heading heading-card">Colazione</p>
                  <div className="d-flex">
                    <p className="macros-items">
                      Kcal {rnd(sumCollazioneEnergy)}
                    </p>
                    <p className="macros-items">
                      Carb {rnd(sumCollazioneCarbs)}
                    </p>
                    <p className="macros-items">
                      Grassi {rnd(sumCollazioneGras)}
                    </p>
                    <p className="macros-items">
                      Proteine {rnd(sumCollazioneProte)}
                    </p>
                  </div>
                </div>
                <div className="d-flex overflow-scroll ">
                  {menuDish?.collazione.length > 0 &&
                    menuDish.collazione.map((item, index) => (
                      <div
                        key={item.id}
                        onClick={() => {
                          dispatch(setItemModalDish(item));
                        }}
                        style={{ position: "relative" }}
                      >
                        <DishDetails
                          id={`${item.id}-${index}`}
                          menu
                          img={item.image}
                          openModal={
                            <DishCard
                              key={index}
                              img={item.image}
                              name={item.name}
                              carb={fix(item.T_carboidrati)}
                              gras={fix(item.T_grassi)}
                              prot={fix(item.T_proteine)}
                              labelText={`${fix(item.T_energia)} kcal`}
                              labelColor={Colors.green}
                            />
                          }
                        />
                        <div
                          style={{ position: "absolute", top: 13, right: 15 }}
                          onClick={() =>
                            dispatch(
                              removeDish({
                                category: "collazione",
                                id: item.id,
                              })
                            )
                          }
                        >
                          <FaWindowClose size={20} color={Colors.red} />
                        </div>
                      </div>
                    ))}

                  {menuFood?.collazione.length > 0 &&
                    menuFood.collazione.map((item, index) => (
                      <div
                        key={item.id}
                        onClick={() => {
                          dispatch(setItemModalFood(item));
                        }}
                        style={{ position: "relative" }}
                      >
                        <AlimentiDetails
                          id={`${item.id}-${index}`}
                          menu
                          img={item.image}
                          openModal={
                            <DishCard
                              key={index}
                              img={item.image}
                              name={item.name}
                              carb={fix(item.carboidrati)}
                              gras={fix(item.grassi)}
                              prot={fix(item.proteine)}
                              labelText={`${fix(item.energia)} kcal`}
                              labelColor={Colors.green}
                            />
                          }
                        />
                        <div
                          style={{ position: "absolute", top: 13, right: 15 }}
                          onClick={() =>
                            dispatch(
                              removeDish({
                                category: "collazione",
                                id: item.id,
                              })
                            )
                          }
                        >
                          <FaWindowClose size={20} color={Colors.red} />
                        </div>
                      </div>
                    ))}

                  <AddDish
                    onClick={() => {
                      dispatch(setCategoryMenu("collazione"));
                      navigate("select-plate");
                    }}
                  />
                </div>
              </Card>
            </div>
            <div className="col-xl-6 col-lg-12">
              <Card>
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <p className="heading heading-card">Pranzo</p>
                  <div className="d-flex">
                    <p className="macros-items">Kcal {rnd(sumPranzoEnergy)}</p>
                    <p className="macros-items">Carb {rnd(sumPranzoCarbs)}</p>
                    <p className="macros-items">Grassi {rnd(sumPranzoGras)}</p>
                    <p className="macros-items">
                      Proteine {rnd(sumPranzoProte)}
                    </p>
                  </div>
                </div>
                <div className="d-flex overflow-scroll ">
                  {menuDish?.pranzo.length > 0 &&
                    menuDish.pranzo.map((item, index) => (
                      <div
                        key={item.id}
                        onClick={() => {
                          dispatch(setItemModalDish(item));
                        }}
                        style={{ position: "relative" }}
                      >
                        <DishDetails
                          id={`${item.id}-${index}`}
                          menu
                          img={item.image}
                          openModal={
                            <DishCard
                              key={index}
                              img={item.image}
                              name={item.name}
                              carb={fix(item.T_carboidrati)}
                              gras={fix(item.T_grassi)}
                              prot={fix(item.T_proteine)}
                              labelText={`${fix(item.T_energia)} kcal`}
                              labelColor={Colors.green}
                            />
                          }
                        />
                        <div
                          style={{ position: "absolute", top: 13, right: 15 }}
                          onClick={() =>
                            dispatch(
                              removeDish({
                                category: "pranzo",
                                id: item.id,
                              })
                            )
                          }
                        >
                          <FaWindowClose size={20} color={Colors.red} />
                        </div>
                      </div>
                    ))}

                  <AddDish
                    onClick={() => {
                      dispatch(setCategoryMenu("pranzo"));
                      navigate("select-plate");
                    }}
                  />
                </div>
              </Card>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6 col-lg-12">
              <Card>
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <p className="heading heading-card">Cena</p>
                  <div className="d-flex">
                    <p className="macros-items">Kcal {rnd(sumCenaEnergy)}</p>
                    <p className="macros-items">Carb {rnd(sumCenaCarbs)}</p>
                    <p className="macros-items">Grassi {rnd(sumCenaGras)}</p>
                    <p className="macros-items">Proteine {rnd(sumCenaProte)}</p>
                  </div>
                </div>
                <div className="d-flex overflow-scroll ">
                  {menuDish?.cena.length > 0 &&
                    menuDish.cena.map((item, index) => (
                      <div
                        key={item.id}
                        onClick={() => {
                          dispatch(setItemModalDish(item));
                        }}
                        style={{ position: "relative" }}
                      >
                        <DishDetails
                          id={`${item.id}-${index}`}
                          menu
                          img={item.image}
                          openModal={
                            <DishCard
                              key={index}
                              img={item.image}
                              name={item.name}
                              carb={fix(item.T_carboidrati)}
                              gras={fix(item.T_grassi)}
                              prot={fix(item.T_proteine)}
                              labelText={`${fix(item.T_energia)} kcal`}
                              labelColor={Colors.green}
                            />
                          }
                        />
                        <div
                          style={{ position: "absolute", top: 13, right: 15 }}
                          onClick={() =>
                            dispatch(
                              removeDish({
                                category: "cena",
                                id: item.id,
                              })
                            )
                          }
                        >
                          <FaWindowClose size={20} color={Colors.red} />
                        </div>
                      </div>
                    ))}

                  <AddDish
                    onClick={() => {
                      dispatch(setCategoryMenu("cena"));
                      navigate("select-plate");
                    }}
                  />
                </div>
              </Card>
            </div>
            <div className="col-xl-6 col-lg-12">
              <Card>
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <p className="heading heading-card">Snack</p>
                  <div className="d-flex">
                    <p className="macros-items">Kcal {rnd(sumSnackEnergy)}</p>
                    <p className="macros-items">Carb {rnd(sumSnackCarbs)}</p>
                    <p className="macros-items">Grassi {rnd(sumSnackGras)}</p>
                    <p className="macros-items">
                      Proteine {rnd(sumSnackProte)}
                    </p>
                  </div>
                </div>
                <div className="d-flex overflow-scroll ">
                  {menuDish?.snack.length > 0 &&
                    menuDish.snack.map((item, index) => (
                      <div
                        key={item.id}
                        onClick={() => {
                          dispatch(setItemModalDish(item));
                        }}
                        style={{ position: "relative" }}
                      >
                        <DishDetails
                          id={`${item.id}-${index}`}
                          menu
                          img={item.image}
                          openModal={
                            <DishCard
                              key={index}
                              img={item.image}
                              name={item.name}
                              carb={fix(item.T_carboidrati)}
                              gras={fix(item.T_grassi)}
                              prot={fix(item.T_proteine)}
                              labelText={`${fix(item.T_energia)} kcal`}
                              labelColor={Colors.green}
                            />
                          }
                        />
                        <div
                          style={{ position: "absolute", top: 13, right: 15 }}
                          onClick={() =>
                            dispatch(
                              removeDish({
                                category: "snack",
                                id: item.id,
                              })
                            )
                          }
                        >
                          <FaWindowClose size={20} color={Colors.red} />
                        </div>
                      </div>
                    ))}

                  <AddDish
                    onClick={() => {
                      dispatch(setCategoryMenu("snack"));
                      navigate("select-plate");
                    }}
                  />
                </div>
              </Card>
            </div>
          </div>
        </div>
      )}
      {location.pathname === "/percorso/alimentazione/select-plate" && (
        <div>
          <SelectPlate />
        </div>
      )}
    </div>
  );
}
